/* frontend/src/pages/Login.css */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #2f3136; /* Match Home background */
    color: #dcddde; /* Match Home text color */
    padding: 20px;
  }
  
  .login-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-header h1 {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .login-header p {
    font-size: 1.2rem;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px; /* Limit width */
  }
  
  .login-form input {
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    background-color: #40444b; /* Match Home input background */
    color: #dcddde; /* Match Home text color */
  }
  
  .login-form button, .login-header button {
    padding: 10px;
    background-color: #5865f2; /* Match Home button color */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .login-form button:hover {
    background-color: #4752c4; /* Darker shade on hover */
  }
  
  .terms-and-conditions {
    margin-top: 20px;
    font-size: 0.9rem;
    text-align: center;
    color: #b9bbbe; /* Lighter text for disclaimer */
  }

  .terms-and-conditions ul {
    list-style-type: none;
  }

  .login-logo {
    width: 250px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space between logo and title */
  }