/* frontend/src/pages/Home.css */
/* /frontend/src/pages/Home.css */

/* Reset Box Sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* General Styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

/* Container */
.container {
    display: flex;
    height: 100vh;
    overflow: hidden; /* Prevent overall page scroll */
}

/* Left Panel */
.left-panel {
    display: flex;
    flex-direction: column;
    width: 300px; /* Adjust as needed */
    background-color: #2f3136;
    color: #fff;
    height: 100vh; /* Full height */
    overflow: hidden; /* Prevent overflow */
}

/* Sidebars */
.sidebars {
    display: flex;
    flex: 1;
    overflow: hidden; /* Prevent overflow */
}

/* Home Icon Container */
.home-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    flex-shrink: 0;
    position: relative;
  }

  .notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #43b581;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
  }

  .unread-badge {
    background-color: #43b581;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    display: inline-flex;
    text-align: center;
    line-height: 1;
  }

  .home-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #36393f;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #dcddde;
    font-size: 24px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: background-color 0.2s, border-radius 0.2s;
  }

  .server-list-scroll-container {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

/* Server Sidebar Container */
.server-sidebar-container {
    display: flex;
    flex-direction: column;
    width: 60px;
    background-color: #2f3136;
    border-right: 2px solid #36393f;
    overflow: hidden;
    position: relative; /* Add this */
}

/* Server Sidebar Content */
.server-sidebar-cont {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

/* Server Sidebar */
.server-sidebar {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 0 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Hide scrollbar for WebKit browsers */
.server-sidebar::-webkit-scrollbar, 
.friends-scroll-container::-webkit-scrollbar, 
.server-list::-webkit-scrollbar, 
.channel-list-content::-webkit-scrollbar {
    display: none; /* WebKit */
}

/* Server List */
.server-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Fade Effect for Server Sidebar */
.server-sidebar-fade {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.server-sidebar-fade-top {
    top: 0;
    background: linear-gradient(to bottom, #2f3136, transparent);
}

.server-sidebar-fade-bottom {
    bottom: 0;
    background: linear-gradient(to top, #2f3136, transparent);
}

.server-sidebar-fade.visible {
    opacity: 1;
}

/* Server Icon */
.server-icon {
    width: 48px;
    height: 48px;
    margin: 8px 0;
    border-radius: 24px;
    background-color: #36393F;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.5s;
    flex-shrink: 0; /* Prevent shrinking */
    position: relative;
}

.server-icon:hover, .home-icon:hover {
    background-color: #40444b;
}

.server-icon.active, .home-icon.active {
    background-color: #5865F2;
}

/* Add Server Button */
.server-icon.add-server {
    background-color: #36393F;
    color: #3ba55d;
    font-size: 24px;
    transition: background-color 0.5s, color 0.5s;
}

.server-icon.add-server:hover {
    background-color: #3ba55d;
    color: #fff;
}

/* Forms in Server Sidebar */
.server-sidebar input[type="text"],
.server-sidebar input[type="checkbox"] {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    padding: 5px;
    border: none;
    border-radius: 4px;
}

.server-sidebar button {
    width: 100%;
    padding: 5px;
    background-color: #5865f2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.server-sidebar button:hover {
    background-color: #4752c4;
}

/* Channel List Container */
.channel-list-container {
    position: relative; /* For positioning fade effects */
    flex: 1; /* Take remaining space */
    display: flex;
    flex-direction: column; /* Stack channel-list vertically */
    overflow: hidden; /* Prevent overflow */
}

/* Channel List */
.channel-list {
    display: flex;
    flex-direction: column; /* Stack header and scroll container */
    flex: 1; /* Expand to fill container */
    background-color: #2f3136;
    color: #fff;
    overflow: hidden; /* Prevent overflow */
    position: relative; /* Establish positioning context */
}

/* Server Header */
.server-header {
    flex: 0 0 auto; /* Prevent from growing or shrinking */
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #36393f;
    padding: 15px 10px;
    background-color: #2f3136; /* Prevent transparency */
    height: 66px; /* Set a specific height */
    width: 100%;
}

/* Server Header Title */
.server-header h2 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(12px, 4vw, 16px); /* Adjust these values as needed */
    line-height: 48px; /* Match the height of the header for vertical centering */
    width: 100%; /* Take full width of container */
}

.server-header-name-container {
  flex: 1;
  min-width: 0; /* Allow container to shrink below content size */

  display: flex; /* Add this */
    align-items: center; /* Add this */
    height: 100%;
}

.server-settings-icon-container {
  flex-shrink: 0; /* Prevent shrinking */
  display: flex;
  align-items: center;
}

.server-settings-icon {
  font-size: 16px;
  cursor: pointer;
}

/* Rotate Settings Icons */
.server-settings-icon, .channel-settings-icon, .user-settings-icon {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.server-settings-icon:hover, .user-settings-icon:hover {
    transform: rotate(180deg);
}

.channel-settings-icon:hover {
    transform: rotate(180deg);
}

.server-settings-icon {
    font-size: 16px;
    margin-left: 10px;
    flex-shrink: 0;
}

.channel-settings-icon {
    margin-left: 10px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s, transform 0.3s ease;
}

/* Scroll Container */
.channel-list-scroll-container {
  flex: 1 1 auto;
  position: relative;
  overflow: hidden; /* Changed from overflow-y: auto */
  background-color: #2f3136;
}

/* Hide scrollbar for WebKit browsers */
.channel-list-content::-webkit-scrollbar {
    display: none; /* WebKit */
}

/* Channel List Content */
.channel-list-content {
  height: 100%;
  overflow-y: auto;
  padding: 0 10px 0 8px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

/* Fade Effect for Channel List */
.channel-list-scroll-fade-top,
.channel-list-scroll-fade-bottom {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    pointer-events: none; /* Allow clicks through gradients */
    z-index: 1; /* Above scrollable content but below header */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.channel-list-scroll-fade-top {
    top: 0; /* Align at the top of scroll container */
    background: linear-gradient(to bottom, rgba(47, 49, 54, 0.8), transparent);
}

.channel-list-scroll-fade-bottom {
    bottom: 0;
    background: linear-gradient(to top, rgba(47, 49, 54, 0.8), transparent);
  }

  .channel-list-scroll-fade-top.visible,
  .channel-list-scroll-fade-bottom.visible {
      opacity: 1;
  }

  /* Channel Item */
  .channel-item {
      background-color: #2f3136;
      margin-bottom: 5px;
      padding: 8px 8px 8px 8px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
  }
  
  .channel-item:hover {
      background-color: #36393f;
  }
  
  .channel-item.active {
      background-color: #393c43;
  }
  
  /* Channel Header */
  .channel-header {
      display: flex;
      align-items: center;
      position: relative; /* Add this */
  }
  
  .channel-icon {
      margin-right: 8px;
      font-size: 16px;
      color: #8e9297;
  }
  
  .channel-name {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  
  .channel-settings-icon {
    margin-left: 10px;
    opacity: 0;
    transition: opacity 0.2s, transform 0.3s ease;
    cursor: pointer;
  }
  
  .channel-item:hover .channel-settings-icon {
      opacity: 1;
  }
  
  .channel-item:hover {
      background-color: #40444b;
  }

  /* User List Container */
  .user-list { 
    padding-left: 8px;
    padding-right: 8px;
}

.channel-list-content ul {
  list-style-type: none;
  padding: 0;
  padding-top: 8px;
  margin: 0;
}
  
  /* User Item */
  .user-item {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      padding: 4px 8px; /* Remove left padding */
      border-radius: 4px;
      transition: background-color 0.2s;
      width: 100%; /* Ensure it takes full width of its container */
  }
  
  .user-item:not(.clickable) {
      opacity: 0.6;
  }
  
  .user-item.clickable:hover {
      background-color: #36393f;
      cursor: pointer;
  }
  
  .user-name {
      cursor: pointer;
      color: #b9bbbe;
      flex-grow: 1;  /* Take remaining space */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;  /* Right margin */
  }
  
  .user-item.clickable:hover .user-name {
      color: #fff;
  }
  
  /* Voice Controls */
  .voice-controls {
      background-color: #2f3136;
      padding: 2px 10px;
      border-top: 1px solid #202225;
      flex-shrink: 0; /* Prevent shrinking */
      display: flex;
      flex-direction: column;
      
  }
  
  .voice-controls h3 {
      margin: 0 0 10px 0;
      font-size: 14px;
      color: #fff;
  }
  
  .voice-controls .controls {
      display: flex;
      gap: 10px;
  }
  
  .voice-controls button {
      flex: 1;
      padding: 10px;
      background-color: #5865F2;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }
  
  /*
  .voice-controls button:hover {
      background-color: #4752c4;
  }
  
  .voice-controls .volume-control {
      margin-top: 10px;
  }
  
  .voice-controls .volume-control label {
      display: flex;
      flex-direction: column;
      color: #b9bbbe;
  }
  
  .voice-controls .volume-control input[type="range"] {
      width: 100%;
      margin-top: 5px;
  }
  */
  
  /* Main Content */
  .main-content {
      flex: 1;
      background-color: #36393F;
      color: #fff;
      padding: 20px;
      overflow-y: auto;
  }
  
  /* Hidden Elements */
  .hidden {
      display: none;
  }


/* User Avatar */
.user-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #7289da;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    flex-shrink: 0;  /* Prevent shrinking */
}

/* Custom Tooltip */
.tooltip-container {
  position: relative;
}

.tooltip {
  position: fixed;
  background-color: #18191c;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
  z-index: 1000;
  transform: translateY(-50%);
}

/* Message Input and Chat Messages (Existing Styles) */
.message-input {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #40444b;
}

.message-input input {
    width: calc(100% - 20px);
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #2f3136;
    color: #fff;
}

.message-input input:focus {
    outline: none;
}

/* Chat Messages */
.chat-messages {
    overflow-y: auto;
    height: calc(100vh - 100px);
    padding-bottom: 50px;
}

.chat-messages .message {
    margin-bottom: 10px;
}

.chat-messages .message strong {
    color: #fff;
}

/* Audio Elements */
audio {
    display: block;
    margin-bottom: 10px;
}

/* Volume Slider */
input[type="range"] {
    width: 100%;
    margin-top: 5px;
}

input[type="range"]:focus {
    outline: none;
}

/* Popups */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    background-color: #36393f;
    color: #dcddde;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
}

.popup-content h3 {
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .popup-content h4 {
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 10px;
  }

.popup-content input[type="text"],
.popup-content input[type="number"],
.popup-content input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #40444b;
  border: 1px solid #202225;
  color: #dcddde;
  border-radius: 3px;
}

.popup-content label {
    display: block;
    margin-top: 10px;
}

.popup-content input[type="range"] {
    width: 100%;
}

.popup-content button {
    margin-top: 15px;
    padding: 10px;
    background-color: #5865f2;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup-content button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #5865f2;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .popup-content button:hover {
    background-color: #4752c4;
  }

  .popup-content .join-code-info {
    background-color: #2f3136;
    padding: 10px;
    border-radius: 3px;
    margin-top: 10px;
  }
  
  .popup-content .join-code-info p {
    margin: 5px 0;
  }

  .join-code-container {
    display: flex;
    align-items: center;
    background-color: #40444b;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .join-code {
    flex-grow: 1;
    padding: 10px;
    margin: 0;
    font-weight: bold;
    color: #ffffff;
  }

  .join-code-input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #40444b;
    border: 1px solid #202225;
    color: #dcddde;
    border-radius: 4px;
  }
  
  .join-code-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #5865f2;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .join-code-button:hover {
    background-color: #4752c4;
  }
  
  .copy-button {
    background-color: #5865f2;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 14px;
    height: 100%;
  }
  
  .copy-button:hover {
    background-color: #4752c4;
  }

  .expiry-info {
    margin-top: 5px;
    font-size: 14px;
    color: #a3a6aa;
  }

.confirmation-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #36393f;
    color: #dcddde;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .confirmation-popup p {
    margin-bottom: 15px;
  }
  
  .confirmation-popup button {
    padding: 8px 15px;
    margin-right: 10px;
    background-color: #5865f2;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .confirmation-popup button:hover {
    background-color: #4752c4;
  }
  
  .confirmation-popup button:last-child {
    background-color: #747f8d;
  }
  
  .confirmation-popup button:last-child:hover {
    background-color: #68727f;
  }
  
  .home-icon-separator {
    width: 100%;
    height: 2px;
    background-color: #36393f;
    margin: 0;
  }
  
  .friends-list {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .friends-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 2px solid #36393f;
    height: 66px;
  }
  
  .add-friend-button, .accept-button, .reject-button {
    background-color: #5865F2;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .add-friend-button svg {
    margin-right: 5px;
  }
  
  .friends-scroll-container {
    flex: 1;
    overflow-y: auto;
  }
  
  .friend-item, .pending-request-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }

  .friend-name {
    flex-grow: 1;
  }

  .pending-request-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: default;
  }
  
  .friend-item:hover, .pending-request-item:hover {
    background-color: #40444b;
  }
  
  .friend-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #7289da;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    flex-shrink: 0;
  }
  
  .direct-messages {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .messages-header {
    padding: 10px;
    border-bottom: 1px solid #40444b;
  }
  
  .messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .message-sender {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .message-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #40444b;
  }
  
  .message-input input {
    flex: 1;
    padding: 5px;
    margin-right: 10px;
  }

  .pending-invite-item {
    position: relative; /* Add this line */
  }

  .server-notification-badge {
    position: absolute;
    top: -5px; /* Adjust as needed */
    right: -5px; /* Adjust as needed */
    background-color: #43b581;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    z-index: 1; /* Ensure the badge is above the server icon */
  }

  .volume-control-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .volume-control {
    position: relative;
    width: 90%;
    padding: 2px 0;
    cursor: pointer;
    display: flex;
    justify-content: center; 
  }
  
  .custom-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 30px; /* Increase the height for better clickability */
    background: transparent;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    cursor: pointer;
    position: relative;
    top: 0px; /* Adjust to center the slider line */
    margin: 0 auto; /* Center the slider */
    box-sizing: border-box;
  }
  
  .custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0;
    height: 0;
    background: transparent;
    cursor: pointer;
  }
  
  .custom-slider::-moz-range-thumb {
    width: 0;
    height: 0;
    background: transparent;
    cursor: pointer;
  }
  
  .custom-thumb {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #5865F2;
    color: white;
    font-size: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  /* User Profile Section */
.user-profile {
  display: flex;
  align-items: center;
  background-color: #292b2f;
  padding: 10px;
  border-top: 1px solid #202225;
}

.user-profile .user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #7289da;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.user-profile .user-name {
  flex-grow: 1;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-profile .user-settings-icon {
  color: #b9bbbe;
  cursor: pointer;
  transition: color 0.2s transform .3s ease;
}

.user-profile .user-settings-icon:hover {
  color: #fff;
}

.popup-content button.logout-button {
  background-color: #ed4245;
  color: white;
  margin-top: 10px;
  width: 100%;
}

.popup-content button.logout-button:hover {
  background-color: #c03537;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it takes full height */
}

.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #36393f;
  color: #dcddde;
  height: 100%; /* Ensure it takes full height */
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* Remove justify-content: flex-end; */
}

.message {
  margin-bottom: 10px;
}

.username {
  font-weight: bold;
  color: #7289da;
}

.message-input-form {
  display: flex;
  padding: 20px;
  background-color: #40444b;
  /* Remove margin-top: auto; */
}

.message-input-form input {
  flex: 1;
  background-color: #40444b;
  border: none;
  color: #dcddde;
  padding: 10px;
  font-size: 16px;
}

.message-input-form button {
  background-color: #7289da;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-left: 10px;
  cursor: pointer;
}

.no-server-selected {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #36393f;
  color: #dcddde;
}

.no-channels-message {
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: left;
  font-weight: bold;
  color: #fff;
  padding-top: 10px;
  padding-left: 2px;
}

.audio-level-indicator {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  margin-top: 10px;
}

.audio-level-bar {
  height: 100%;
  transition: width 0.05s ease, background-color 0.05s ease;
}